import React from 'react'
import { Container, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const FeaturesWithPhotoBlock03 = ({ content: { text, buttons, images } }) => (

  <>

     <h1 style={{ textAlign: "center", marginBottom: '40px', fontWeight: "bold", color: "var(--theme-ui-colors-heading,#2d3748)", fontSize: "2.25rem" }}>Our Development Process</h1>

     <div style={{ textAlign: "center", marginBottom: '40px', color: "var(--theme-ui-colors-omegaDark,#718096)", fontSize: "1.25rem" }} >Software development work into sequential steps to improve design, product management. </div>
  <Container sx={{ textAlign: `center` }}>
       
    <Box>
      {text && (
        <Reveal effect='fadeInDown'>
          <ContentText content={text} />
        </Reveal>
      )}
      {buttons && (
        <>
          <Divider space={3} />
          <ContentButtons content={buttons} />
        </>
      )}
    </Box>
    {images && (
      <>
        <Divider space={3} />
        <Box sx={{ position: `relative` }}>
          <ContentImages
            content={{ images }}
            loading='eager'
            imagePosition='center'
            imageEffect='fadeInDown'
          />
        </Box>
      </>
    )}
  </Container>
  </>

)

export default WithDefaultContent(FeaturesWithPhotoBlock03)
